import 'popper.js'
import '../src/init_jquery'
import 'highcharts'
import './cookie' // javascript_pack_tag "cookie" in customers#index
import 'bootstrap'
import 'bootstrap-datepicker'
import '../src/bootstrap-multiselect'
import '../src/bootstrap.js.coffee'
import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'

Turbolinks.start()
Rails.start()

window.openTrashModal = function (ic, name, id) {
  if(id){
      $('.modal-body').html('<p>Wollen Sie wirklich, ein Produkt zu löschen?</p>');
      $('.modal-footer').html("<button type='button' class='btn btn-primary' data-dismiss='modal'>Abbrechen</button><a data-method='delete' class='btn btn-danger' href='/customers/" + ic + "/insurances/" + id + "' rel='nofollow'><span class='fa fa-trash'> löschen</span></a>");
  }else {
      $('.modal-body').html('<p>Wollen Sie wirklich den Benutzer zu löschen?</p>');
      $('.modal-footer').html("<button type='button' class='btn btn-primary' data-dismiss='modal'>Abbrechen</button><a data-method='delete' class='btn btn-danger' href='/customers/" + ic + "' rel='nofollow'><span class='fa fa-trash'> löschen</span></a>");
  }
  $('.modal-title').html("Löschen "+name);
  $('#trashModal').modal('show');
}

$(document).on('turbolinks:load', function () {
  $('p.back-to-list').html("<a href='" + getCookie("list-path") + "'>< zurück zur Liste</a>");

  $("input[data-provide='datepicker']").datepicker({ weekStart: 1 });
})

window.getCookie = function (cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length,c.length);
      }
  }
  return "";
}
